import React from "react";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { visit_GetByPhoneNumber } from "../../ApiClient";
import {
  TableRow,
  TableCell,
  Paper,
  TableHead,
  TableContainer,
  TableBody,
  Table,
  withStyles,
} from "@material-ui/core";
import {
  getColorByStatus,
  getStatusLabelByColor,
} from "../Calendar/ApprovalStatus";

const styles = (theme) => ({
  table: {
    width: 800,
  },
});

class ClientVisits extends React.Component {
  state = {
    visits: [],
    phoneNumber: "",
  };

  componentDidMount() {
    const { phoneNumber } = this.props.match.params;
    visit_GetByPhoneNumber(phoneNumber).then((visits) =>
      this.setState({ visits, phoneNumber })
    );
  }

  render() {
    const { classes } = this.props;
    const { phoneNumber, visits } = this.state;

    return (
      <>
        <h1>Registruoti vizitai {phoneNumber}</h1>

        <TableContainer component={Paper} className={classes.table}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Vizito data</TableCell>
                <TableCell>Darbuotojas</TableCell>
                <TableCell>Komentaras</TableCell>
                <TableCell>Užrašai</TableCell>
                <TableCell>Statusas</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {visits.map((row) => {
                const statusColor = getColorByStatus(
                  row.smsAprovalStatus,
                  row.reminderId
                );
                const statusLabel = getStatusLabelByColor(statusColor);
                const eventDate = moment.utc(row.visitDate).local().format('YYYY-MM-DD HH:mm');

                return (
                  <TableRow key={row.visitId}>
                    <TableCell>{eventDate}</TableCell>
                    <TableCell>{row.employeeName}</TableCell>
                    <TableCell>{row.comment}</TableCell>
                    <TableCell>{row.notes}</TableCell>
                    <TableCell>
                      <span
                        class="circle"
                        style={{ backgroundColor: statusColor.primary }}
                      ></span>
                      {statusLabel}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(ClientVisits);
