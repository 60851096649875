import moment from "moment";
import React, { isValidElement } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MomentUtils from "@date-io/moment";
import Select from "@material-ui/core/Select";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";
import SendIcon from "@material-ui/icons/Send";
import ChatIcon from "@material-ui/icons/Chat";
import {
  visit_UpdateVisit,
  visit_CreateVisit,
  approveVisit_Approve,
  remindVisit_Remind,
} from "../../ApiClient";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { colors } from "./ApprovalStatus";
import { message_GetMessagesIncludingNumber } from "../../ApiClient";
import { authenticationService } from "../../services";
import { UserRole } from "../../enums.js";

const styles = (theme) => ({
  container: {
    minWidth: "500px",
  },
  textField: {
    width: "100%",
    paddingBottom: "1.25rem",
    marginTop: "0",
  },
  selectField: {
    width: "100%",
  },
  saveButton: {
    margin: "10px",
  },
  approveVisitButton: {
    backgroundColor: colors.green.primary,
  },
  sendReminderButton: {
    backgroundColor: colors.violet.primary,
  },
});

const strings = {
  title: "Vizitas",
  buttons: {
    save: "Išsaugoti",
    cancel: "Atšaukti",
    delete: "Naikinti",
    approveVisit: "Patvirtinti dalyvavimą",
    checkHistory: "Žinučių istorija",
    remindVisit: "Išsiųsti priminimą",
  },
  inputs: {
    title: "Pavadinimas",
    notes: "Užrašai",
    phoneNumber: "Telefono numeris",
    template: "Šablonas",
    employee: "Darbuotojas",

    dateStart: "Pradžios Data",
    timeStart: "Pradžios Laikas",

    dateEnd: "Pabaigos Data",
    timeEnd: "Pabaigos Laikas",
  },
};

class EditEventModal extends React.Component {
  constructor(props) {
    super(props);

    const { eventData } = props;

    const dateStart = eventData?.start_time || moment();
    const dateEnd = eventData?.end_time || moment();

    const stateData = {
      title: "",
      titleValue: "",
      notes: "",
      phoneNumber: "",
      phoneNumberValue: "+370",
      templateId: 1,
      employeeId: eventData?.group || 1,
      isBlock: !!eventData?.metadata?.isBlock,
      messageHistory: [],

      dateEnd: dateEnd,
      timeEnd: dateEnd.format("HH:mm"),

      dateStart: dateStart,
      timeStart: dateStart.format("HH:mm"),
    };

    if (props.eventData && props.eventData.id) {
      const { eventData } = this.props;
      stateData.title = eventData.title;
      stateData.titleValue = eventData.title;
      stateData.notes = eventData.metadata.notes;
      stateData.phoneNumber = eventData.metadata.phoneNumber;
      stateData.phoneNumberValue = eventData.metadata.phoneNumber;
      stateData.templateId = eventData.metadata.templateId;
      stateData.employeeId = eventData.group;
      stateData.smsAprovalStatus = eventData.metadata.smsAprovalStatus;

      stateData.dateEnd = eventData.end_time.local();
      stateData.timeEnd = eventData.end_time.format("HH:mm");

      stateData.dateStart = eventData.start_time.local();
      stateData.timeStart = eventData.start_time.format("HH:mm");
    }

    this.state = {
      ...stateData,
    };

    message_GetMessagesIncludingNumber(stateData.phoneNumber).then((messages) =>
      this.setState({
        messageHistory: messages
          .filter((x) => x.sender === stateData.phoneNumber)
          .sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated))
          .slice(0, 5),
      })
    );
  }

  handleChange = (event) => {
    const target = event.target;
    const newState = { [target.name]: target.value };

    if (target.name === "timeStart") {
      const timeStr = target.value.split(":");
      let hourInt = parseInt(timeStr[0]);
      if (!Number.isNaN(hourInt)) {
        hourInt++;
        let hourStr = hourInt > 9 ? hourInt : `0${hourInt}`;
        if (parseInt(hourStr) >= 24) hourStr = timeStr[0];
        newState["timeEnd"] = `${hourStr}:${timeStr[1]}`;
      }
    } else if (target.name === "title") {
      const selectedPreset = this.props.prefillNames.find(
        (x) => x.title === target.value
      );
      if (selectedPreset) {
        newState["phoneNumber"] = selectedPreset.phoneNumber;
        newState["phoneNumberValue"] = selectedPreset.phoneNumber;
      } else {
        newState["phoneNumber"] = "+370";
        newState["phoneNumberValue"] = "+370";
      }
    }

    this.setState(newState);
  };

  handleStartDateChange = (date) => {
    this.setState({ dateStart: date, dateEnd: date });
  };

  handleEndDateChange = (date) => {
    this.setState({ dateEnd: date });
  };

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  onApproveVisit = () =>
    approveVisit_Approve(this.props.eventData?.id).then((newEvent) => {
      this.setState({ smsAprovalStatus: 2 });
      this.props.onAddedEventCallback(newEvent);
    });

  onSendReminder = () => {
    remindVisit_Remind(this.props.eventData?.id).then((newEvent) => {
      this.setState({ smsAprovalStatus: 1 });
      this.props.onAddedEventCallback(newEvent);
    });
  };

  onClose = () => this.props.onClose();

  onSave = () => {
    const {
      title,
      notes,
      phoneNumber,
      templateId,
      employeeId,
      isBlock,
      dateStart,
      timeStart,
      dateEnd,
      timeEnd,
    } = this.state;
    const momentTimeStart = moment(timeStart, "HH:mm");
    const momentTimeEnd = moment(timeEnd, "HH:mm");

    dateStart.set({
      hour: momentTimeStart.get("hour"),
      minute: momentTimeStart.get("minute"),
    });

    dateEnd.set({
      hour: momentTimeEnd.get("hour"),
      minute: momentTimeEnd.get("minute"),
    });

    const newItemDto = {
      comment: title,
      notes: notes,
      phoneNumber: phoneNumber,
      templateId: templateId,
      employeeId: employeeId,
      isBlock: isBlock,

      visitDate: dateStart,
      endDate: dateEnd,
    };

    const { eventData } = this.props;
    if (eventData && eventData.id) {
      newItemDto.visitId = eventData.id;
      visit_UpdateVisit(newItemDto).then((newEvent) => {
        this.props.onAddedEventCallback(newEvent);
      });
    } else {
      visit_CreateVisit(newItemDto).then((newEvent) => {
        this.props.onAddedEventCallback(newEvent);
      });
    }
  };

  showDeleteButton = () => this.props.eventData && this.props.eventData.id;

  render() {
    const {
      classes,
      employees,
      templates,
      onDelete,
      phoneNumbers,
      prefillNames,
    } = this.props;
    const { smsAprovalStatus, title, notes, titleValue } = this.state;
    const currentUserValue = authenticationService.currentUserValue;
    const isAdmin = currentUserValue?.role === UserRole.Admin;

    return (
      <div className={classes.container}>
        <h2>{strings.title}</h2>

        <Autocomplete
          freeSolo
          disableClearable
          id="textfield-title"
          options={prefillNames.map((x) => x.title)}
          name="title"
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.textField}
              required
              label={strings.inputs.title}
              margin="normal"
              name="title"
              InputProps={{ ...params.InputProps, type: "search" }}
            />
          )}
          inputValue={title}
          value={titleValue}
          onInputChange={(event, val) =>
            this.handleChange({
              target: { value: val, name: "title" },
            })
          }
        />

        {!this.state.isBlock && (
          <Autocomplete
            freeSolo
            disableClearable
            id="textfield-phoneNumber-autofill"
            options={phoneNumbers}
            name="phoneNumber"
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.textField}
                required
                label={strings.inputs.phoneNumber}
                margin="normal"
                name="phoneNumber"
                InputProps={{ ...params.InputProps, type: "search" }}
              />
            )}
            inputValue={this.state.phoneNumber}
            value={this.state.phoneNumberValue}
            onInputChange={(event, val) =>
              this.handleChange({
                target: { value: val, name: "phoneNumber" },
              })
            }
          />
        )}

        {!this.state.isBlock && (
          <TextField
            className={classes.textField}
            id="textfield-notes"
            name="notes"
            label={strings.inputs.notes}
            value={this.state.notes}
            onChange={this.handleChange}
          />
        )}

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
              <KeyboardDatePicker
                className={classes.textField}
                required
                disableToolbar
                variant="inline"
                format="MM/DD/yyyy"
                margin="normal"
                id="textfield-date-start"
                label={strings.inputs.dateStart}
                value={this.state.dateStart}
                onChange={this.handleStartDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <TextField
              className={classes.textField}
              required
              id="time-start"
              name="timeStart"
              label={strings.inputs.timeStart}
              type="time"
              defaultValue="00:00"
              value={this.state.timeStart}
              onChange={this.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 30 * 60, // 30 min
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
              <KeyboardDatePicker
                className={classes.textField}
                required
                disableToolbar
                variant="inline"
                format="MM/DD/yyyy"
                margin="normal"
                id="textfield-date-end"
                label={strings.inputs.dateEnd}
                value={this.state.dateEnd}
                onChange={this.handleEndDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <TextField
              className={classes.textField}
              required
              id="time-end"
              name="timeEnd"
              label={strings.inputs.timeEnd}
              type="time"
              defaultValue="00:00"
              value={this.state.timeEnd}
              onChange={this.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 30 * 60, // 30 min
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          {!this.state.isBlock && (
            <Grid item xs={6}>
              <InputLabel id="selectfield-template-label" required>
                {strings.inputs.template}
              </InputLabel>
              <Select
                className={classes.selectField}
                labelId="selectfield-template-label"
                id="selectfield-template"
                value={this.state.templateId}
                name="templateId"
                onChange={this.handleChange}
              >
                {templates.map((template) => (
                  <MenuItem
                    key={template.templateId}
                    value={template.templateId}
                  >
                    {template.title}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          <Grid item xs={6}>
            <InputLabel id="selectfield-employee-label" required>
              {strings.inputs.employee}
            </InputLabel>
            <Select
              className={classes.selectField}
              labelId="selectfield-employee-label"
              id="selectfield-employee"
              value={this.state.employeeId}
              name="employeeId"
              onChange={this.handleChange}
            >
              {employees.map((employee) => (
                <MenuItem key={employee.id} value={employee.id}>
                  {employee.title}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>

        <br />

        <Button
          className={classes.saveButton}
          startIcon={<SaveIcon />}
          variant="contained"
          color="primary"
          onClick={this.onSave}
        >
          {strings.buttons.save}
        </Button>

        <Button
          className={classes.saveButton}
          startIcon={<ClearIcon />}
          variant="contained"
          color="secondary"
          onClick={this.onClose}
        >
          {strings.buttons.cancel}
        </Button>

        {this.showDeleteButton() && (
          <Button
            className={classes.saveButton}
            startIcon={<DeleteIcon />}
            variant="contained"
            color="secondary"
            onClick={onDelete}
          >
            {strings.buttons.delete}
          </Button>
        )}

        <br />

        {!this.state.isBlock &&
          smsAprovalStatus &&
          parseInt(smsAprovalStatus) !== 2 && (
            <>
              <Button
                className={`${classes.approveVisitButton} ${classes.saveButton}`}
                startIcon={<DoneIcon />}
                variant="contained"
                onClick={this.onApproveVisit}
              >
                {strings.buttons.approveVisit}
              </Button>

              {isAdmin && (
                <Button
                  className={`${classes.sendReminderButton} ${classes.saveButton}`}
                  startIcon={<SendIcon />}
                  variant="contained"
                  onClick={this.onSendReminder}
                >
                  {strings.buttons.remindVisit}
                </Button>
              )}
            </>
          )}

        {this.state.messageHistory && parseInt(smsAprovalStatus) === 4 && (
          <>
            <h2>Paskiausios žinutės</h2>
            <table>
              <tbody>
                {this.state.messageHistory.map((msg) => (
                  <tr>
                    <td>{moment.utc(msg.dateCreated).format("YYYY-MM-DD")}</td>
                    <td>{msg.body}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(EditEventModal);
