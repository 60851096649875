import moment from "moment";
import { momentLocalizer } from "react-big-calendar";

const minDateTime = new Date(2018, 0, 29, 7, 0, 0);
const maxDateTime = new Date(2018, 0, 29, 20, 0, 0);

const localizer = momentLocalizer(moment);
const calendarMessages = {
  date: "Data",
  time: "Laikas",
  event: "Vizitas",
  allDay: "Visa diena",
  week: "Savaitė",
  work_week: "Darbo savaitė",
  day: "Diena",
  month: "Mėnesis",
  previous: "Praeita",
  next: "Sekanti",
  yesterday: "Vakar",
  tomorrow: "Rytoj",
  today: "Šiandien",
  agenda: "Tvarkaraštis",

  noEventsInRange: "There are no events in this range.",

  showMore: (total) => `+${total} more`,
};

export const calendarDefaults = {
  min: minDateTime,
  max: maxDateTime,
  step: 30,
  timeslots: 1,
  messages: calendarMessages,
  localizer,
  showMultiDayTimes: true,
};
