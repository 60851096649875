import React from "react";
import ChatIcon from "@material-ui/icons/Chat";
import EventIcon from "@material-ui/icons/Event";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";
import { phoneNumbers_GetWithComments } from "../../ApiClient";
import TextField from "@material-ui/core/TextField";
import {
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  Paper,
  TableHead,
  TableContainer,
  TableBody,
  Table,
  withStyles,
} from "@material-ui/core";

const styles = (theme) => ({
  table: {
    width: 600,
  },
  filterField: {
    paddingBottom: 16,
  },
});

class ClientSearch extends React.Component {
  state = { phoneNumbers: [], isLoading: true, filter: "" };

  constructor(props) {
    super(props);

    phoneNumbers_GetWithComments().then((x) =>
      this.setState({ phoneNumbers: x, isLoading: false })
    );
  }

  onClickViewHistory = ({ phoneNumber }) =>
    this.props.history.push(`/messages/${phoneNumber}`);

  onClickViewVisits = ({ phoneNumber }) =>
    this.props.history.push(`/client-visits/${phoneNumber}`);

  handleChange = (event) => {
    const target = event.target;
    const newState = { [target.name]: target.value };
    this.setState(newState);
  };

  render() {
    const { classes } = this.props;
    const { phoneNumbers, filter } = this.state;
    return (
      <div>
        <h1>Klientų paieška</h1>

        <TextField
          className={classes.filterField}
          label={"Paieška"}
          name="filter"
          value={filter}
          onChange={this.handleChange}
        />

        <TableContainer className={classes.table} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "20%" }}>Veiksmai</TableCell>
                <TableCell>Numeris</TableCell>
                <TableCell>Pavadinimas</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {phoneNumbers.length > 0 &&
                phoneNumbers
                  .filter((x) => x.phoneNumber.includes(filter) || x.comment.toLowerCase().includes(filter.toLowerCase()))
                  .map((x) => (
                    <TableRow key={x.phoneNumber}>
                      <TableCell>
                        <Tooltip title={"Peržiūrėti žinutes"}>
                          <IconButton
                            onClick={() => this.onClickViewHistory(x)}
                          >
                            <ChatIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={"Peržiūrėti vizitus"}>
                          <IconButton
                            onClick={() => this.onClickViewVisits(x)}
                          >
                            <EventIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell>{x.phoneNumber}</TableCell>
                      <TableCell>{x.comment}</TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(ClientSearch);
