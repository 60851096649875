export const POPUP_OPEN = "POPUP_OPEN";
export const POPUP_CLOSE = "POPUP_CLOSE";

export const popupOpen = (text, severity) => {
  return {
    type: POPUP_OPEN,
    text: text,
    severity: severity,
  };
};

export const popupClose = () => {
  return {
    type: POPUP_CLOSE,
  };
};
