import React from "react";
import { Calendar, Views } from "react-big-calendar";
import "./CalendarStyles.css";
import moment from "moment";
import {
  getColorByStatus,
  ApprovalStatusLegend,
} from "../Calendar/ApprovalStatus";
import {
  visit_getAll,
  visit_DeleteVisit,
  template_GetTemplates,
  employee_GetEmployees,
  nwh_GetAll,
  phoneNumbers_GetAll,
} from "../../ApiClient";
import {
  DialogContent,
  Dialog,
  Backdrop,
  CircularProgress,
  Checkbox,
  TextField,
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import EditEventModal from "../Calendar/EditEventModal";
import { withStyles } from "@material-ui/core/styles";
import { calendarDefaults } from "../Common";
import Toolbar from "./Toolbar";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const eventPropGetter = (event, start, end, isSelected) => {
  const { smsAprovalStatus, reminderId, isBlock, phoneNumber } = event.metadata;

  const className =
    isBlock || phoneNumber == "+370" || phoneNumber == ""
      ? "calendar__event--block"
      : "";
  const { primary: backgroundColor, secondary: borderColor } = getColorByStatus(
    smsAprovalStatus,
    reminderId
  );

  return {
    className: className,
    style: { backgroundColor, borderColor },
  };
};

const styles = (theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    "& > *": {
      marginLeft: "15px",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  calendar: {
    height: "95%",
    ["@media (max-width: 960px)"]: { height: "auto" },
  },
  employeeSelect: {
    marginLeft: "20px",
  },
});

class CalendarResourceGrid extends React.Component {
  intervalID;
  state = {
    events: [],
    isLoadingVisits: true,

    templates: [],
    isLoadingTemplates: true,

    employeeFilterIds: [],
    employees: [],
    isLoadingEmployees: true,

    nonWorkingHours: {},
    isNonWorkingHoursLoading: true,

    phoneNumbers: [],
    isPhoneNumbersLoading: true,

    modalOpen: false,
    isLoading: false,
    editingEvent: {},
  };

  isLoading = () =>
    this.state.isLoadingTemplates ||
    this.state.isLoadingVisits ||
    this.state.isLoadingEmployees ||
    this.state.isNonWorkingHoursLoading ||
    this.state.isPhoneNumbersLoading;

  componentDidMount() {
    this.intervalID = setInterval(() => {
      visit_getAll().then((x) =>
        this.setState({ events: x, isLoadingVisits: false })
      );
    }, 60000);

    visit_getAll().then((x) =>
      this.setState({ events: x, isLoadingVisits: false })
    );

    template_GetTemplates().then((x) =>
      this.setState({ templates: x, isLoadingTemplates: false })
    );

    employee_GetEmployees().then((x) => {
      const employees = x.map((employee) => ({
        id: employee.employeeId,
        title: employee.name,
      }));
      this.setState({
        employees,
        isLoadingEmployees: false,
      });
    });

    nwh_GetAll().then((nwh) => {
      const nonWorkingHours = nwh
        .map((x) => {
          const s = x.start.toDate();
          const e = x.end.toDate();
          return {
            employeeId: x.employeeId,
            day: s.getDay(),
            start: this.getTimeStr(s),
            end: this.getTimeStr(e),
          };
        })
        .reduce((result, x) => {
          result[`${x.employeeId}_${x.day}`] = [
            ...(result[`${x.employeeId}_${x.day}`] || []),
            x,
          ];
          return result;
        }, []);

      this.setState({
        nonWorkingHours,
        isNonWorkingHoursLoading: false,
      });
    });

    phoneNumbers_GetAll().then((phoneNumbers) =>
      this.setState({ phoneNumbers, isPhoneNumbersLoading: false })
    );
  }

  onModalClose = () => this.setState({ modalOpen: false, editingEvent: {} });

  onAddedEventCallback = (newEvent) => {
    let { events } = this.state;

    const itemIndex = events.findIndex((x) => x.id === newEvent.id);
    if (itemIndex > -1) {
      events[itemIndex] = { ...events[itemIndex], ...newEvent };
    } else {
      events = events.concat([newEvent]);
    }

    this.setState({ events: [].concat(events) });
    this.onModalClose();
  };

  onEventDeleteCallback = () => {
    this.setState({ isLoading: true }, () =>
      visit_DeleteVisit(this.state.editingEvent.id).then(() => {
        const newItems = this.state.events.filter(
          (x) => x.id !== this.state.editingEvent.id
        );
        this.setState({ events: newItems, isLoading: false });
        this.onModalClose();
      })
    );
  };

  onSelectEvent = (event) => {
    this.setState({ editingEvent: event, modalOpen: true });
  };

  onSelectSlot = ({ start, end, resourceId }) =>
    this.setState({
      modalOpen: true,
      editingEvent: {
        group: resourceId,
        end_time: moment(end),
        start_time: moment(start),
      },
    });

  getTimeStr = (date) =>
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2);

  isNonWorkSlot = (date, resourceId) => {
    const time = this.getTimeStr(date);

    return this.state.nonWorkingHours[`${resourceId}_${date.getDay()}`]?.some(
      (x) => time >= x.start && time < x.end
    );
  };

  slotPropGetter = (date, resourceId) => ({
    style: this.isNonWorkSlot(date, resourceId) ? { background: "red" } : {},
  });

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  Event({ event }) {
    return (
      <span>
        <strong>{event.title}</strong>
        {event.metadata.notes && ':  ' + event.metadata.notes}
      </span>
    )
  }

  render() {
    const {
      events,
      modalOpen,
      employees,
      templates,
      editingEvent,
      phoneNumbers,
      employeeFilterIds,
    } = this.state;
    const { classes } = this.props;
    const isMobile = window.matchMedia("(max-width: 960px)").matches;

    return (
      <>
        <tr>
          <td>
            <h1>Vizitų kalendorius</h1>
          </td>
          <td>
            <ApprovalStatusLegend />
          </td>
          <td>
            <Autocomplete
              multiple
              onChange={(event, newValue) =>
                this.setState({
                  employeeFilterIds: newValue.map((x) => x.id),
                })
              }
              id="checkboxes-employees"
              options={employees}
              disableCloseOnSelect
              getOptionLabel={(option) => option.title}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.title}
                </React.Fragment>
              )}
              className={classes.employeeSelect}
              style={{ minWidth: "200px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Paieška"
                  placeholder="Darbuotojai"
                />
              )}
            />
          </td>
        </tr>

        {!this.isLoading() && (
          <Calendar
            selectable
            className={classes.calendar}
            events={events}
            defaultView={Views.DAY}
            views={["day", "work_week"]}
            eventPropGetter={eventPropGetter}
            onSelectSlot={this.onSelectSlot}
            onSelectEvent={this.onSelectEvent}
            resources={employees.filter(
              (x) =>
                employeeFilterIds.length == 0 ||
                employeeFilterIds.some((y) => y == x.id)
            )}
            resourceIdAccessor="id"
            resourceTitleAccessor="title"
            startAccessor={({ start_time }) => new Date(start_time)}
            endAccessor={({ end_time }) => new Date(end_time)}
            resourceAccessor={({ group }) => group}
            slotPropGetter={this.slotPropGetter}
            components={{ toolbar: Toolbar, event: this.Event }}
            {...calendarDefaults}
          />
        )}
        <Dialog
          fullScreen={isMobile}
          open={modalOpen}
          onClose={this.onModalClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <EditEventModal
              onAddedEventCallback={this.onAddedEventCallback}
              onClose={this.onModalClose}
              employees={employees}
              templates={templates}
              eventData={editingEvent}
              onDelete={this.onEventDeleteCallback}
              phoneNumbers={phoneNumbers}
              prefillNames={Object.values(
                events
                  .filter(
                    (x) =>
                      !["+370", ""].some((y) => y === x.metadata.phoneNumber)
                  )
                  .map((x) => ({
                    title: x.title,
                    phoneNumber: x.metadata.phoneNumber,
                  }))
                  .reduce((r, x) => {
                    r[x.phoneNumber] = x;
                    return r;
                  }, {})
              )}
            />
          </DialogContent>
        </Dialog>
        <Backdrop className={classes.backdrop} open={this.isLoading()}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CalendarResourceGrid);
