import React from "react";
import clsx from "clsx";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import compose from "recompose/compose";
import { withRouter } from "react-router";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import SubjectIcon from "@material-ui/icons/Subject";
import { authenticationService } from "../../services";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import { UserRole } from "../../enums.js";

const drawerWidth = 240;
const styles = (theme) => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      flexShrink: 0,
    },
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaper: {
    width: drawerWidth,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.dark,
  },
});

class SideNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
    };
  }

  logOut = () => {
    authenticationService.logout();
    this.props.history.push("/login");
  };

  navLinkList = [
    {
      icon: <DateRangeIcon color="primary" />,
      url: "/",
      text: "Kalendorius",
    },
    {
      icon: <AssignmentIndIcon color="primary" />,
      url: "/employees",
      text: "Darbuotojai",
      roles: [UserRole.Admin],
    },
    {
      icon: <SubjectIcon color="primary" />,
      url: "/templates",
      text: "Šablonai",
      roles: [UserRole.Admin],
    },
    {
      icon: <SearchIcon color="primary" />,
      url: "/client-search",
      text: "Klientų paieška",
      roles: [UserRole.Admin],
    },
    {
      icon: <VpnKeyIcon color="primary" />,
      url: "/2fa-code",
      text: "Twilio Kodas",
      roles: [UserRole.Admin],
    },
  ];
  actionLinkList = [
    {
      icon: <ExitToAppIcon color="primary" />,
      onClick: this.logOut,
      text: "Logout",
    },
  ];

  render() {
    const { classes } = this.props;
    const open = this.state.mobileOpen;
    const currentUserValue = authenticationService.currentUserValue;

    const handleDrawerToggle = () => {
      this.setState({ mobileOpen: !this.state.mobileOpen });
    };

    const drawer = (
      <div>
        <List>
          <ListItem button onClick={handleDrawerToggle}>
            <ListItemIcon>
              <MenuIcon />
            </ListItemIcon>
          </ListItem>
        </List>
        <Divider />
        <List>
          {this.navLinkList
            .filter(
              (link) =>
                !link.roles || link.roles.includes(currentUserValue?.role)
            )
            .map((navLink) => (
              <Link key={navLink.url} className={classes.link} to={navLink.url}>
                <ListItem button>
                  <ListItemIcon>{navLink.icon}</ListItemIcon>
                  <ListItemText primary={navLink.text} />
                </ListItem>
              </Link>
            ))}
        </List>
        <Divider />
        <List>
          {this.actionLinkList.map((actionLink) => (
            <ListItem button onClick={actionLink.onClick}>
              <ListItemIcon>{actionLink.icon}</ListItemIcon>
              <ListItemText primary={actionLink.text} />
            </ListItem>
          ))}
        </List>
      </div>
    );

    return (
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(SideNav);
