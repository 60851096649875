import React from "react";
import { Table } from "..";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import {
  employee_GetEmployees,
  employee_CreateEmployee,
  employee_UpdateEmployee,
  employee_DeleteEmployee,
} from "../../ApiClient";
import WorkOffIcon from "@material-ui/icons/WorkOff";
import { withRouter } from "react-router-dom";

const styles = (theme) => ({
  table: {
    width: 900,
  },
});

class EmployeeList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      employees: [],
      isLoading: true,
    };

    employee_GetEmployees().then((x) =>
      this.setState({ employees: x, isLoading: false })
    );
  }

  columns = [
    { title: "Vardas", key: "name" },
    { title: "Prisijungimas", key: "username" },
    { title: "Slaptažodis", key: "password", type: "password" },
  ];

  extraActions = [
    {
      name: "Ne darbo valandos",
      icon: <WorkOffIcon fontSize="small" />,
      onClick: ({ employeeId }) =>
        this.props.history.push(`/employees/${employeeId}`),
    },
  ];

  onSave = (employee) => {
    if (employee.employeeId && employee.employeeId !== 0) {
      return employee_UpdateEmployee(employee).then((updatedEmployee) => {
        const { employees } = this.state;
        const updatedIndex = employees.findIndex(
          (x) => x.employeeId == updatedEmployee.employeeId
        );
        if (updatedIndex < 0 || updatedEmployee.employeeId < 1) {
          // TODO: Handle error
          return;
        }
        employees[updatedIndex] = updatedEmployee;
        this.setState({ employees });
        return updatedEmployee;
      });
    } else {
      return employee_CreateEmployee(employee).then((newEmployee) => {
        const { employees } = this.state;
        employees.push(newEmployee);
        this.setState({ employees });
        return newEmployee;
      });
    }
  };

  onDelete = (employee) =>
    employee_DeleteEmployee(employee.employeeId).then(() => {
      const newEmployees = this.state.employees.filter(
        (x) => x.employeeId != employee.employeeId
      );

      this.setState({ employees: newEmployees });
    });

  render() {
    const { classes } = this.props;
    return (
      <div>
        <h1>Darbuotojai</h1>
        <Table
          className={classes.table}
          columns={this.columns}
          data={this.state.employees}
          onSave={this.onSave}
          onDelete={this.onDelete}
          isLoading={this.state.isLoading}
          extraActions={this.extraActions}
        />
      </div>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(EmployeeList);
