import { LinearProgress } from "@material-ui/core";
import React from "react";
import { getCurrentCode } from "../../helpers/2fa";

export default class TwoFA extends React.Component {
  state = {
    currentCode: "",
    secret: "5TJKSMQUT4E2FQNJSUP3KTWQMUENKPNWBEWP6MH7SPP3YAIOJL2A",
    currentProgress: 0,
  };
  intervalId = null;

  componentDidMount() {
    this.updateOTP();
    this.intervalId = setInterval(this.onTimerTick, 1000);
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  updateOTP = () => {
    const currentCode = getCurrentCode(this.state.secret);
    this.setState({ currentCode });
  };

  onTimerTick = () => {
    var epoch = Math.round(new Date().getTime() / 1000.0);
    var countDown = 30 - (epoch % 30);
    if (epoch % 30 == 0) this.updateOTP();
    var progress = (countDown / 30) * 100;
    this.setState({ currentProgress: progress });
  };

  render() {
    const { currentProgress, currentCode } = this.state;

    return (
      <div style={{ width: "200px" }}>
        <h1>Twillio kodas</h1>
        <h2>{currentCode}</h2>
        <LinearProgress variant="determinate" value={currentProgress} />
      </div>
    );
  }
}
