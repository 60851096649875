import React from "react";
import { getColorByStatus } from "./ApprovalStatus";

export default function ItemRender({
  item,
  timelineContext,
  itemContext,
  getItemProps,
  getResizeProps,
}) {
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
  const { smsAprovalStatus, reminderId, isBlock } = item.metadata;
  const { primary: backgroundColor, secondary: borderColor } = getColorByStatus(
    smsAprovalStatus,
    reminderId
  );

  const background = isBlock
    ? "repeating-linear-gradient(45deg, #606dbc, #606dbc 10px, #465298 10px, #465298 20px"
    : backgroundColor;

  return (
    <div
      {...getItemProps({
        style: {
          backgroundColor,
          background,
          color: item.color,
          borderColor,
          borderStyle: "solid",
          borderWidth: 1,
          borderRadius: 4,
          borderLeftWidth: itemContext.selected ? 3 : 1,
          borderRightWidth: itemContext.selected ? 3 : 1,
        },
        onMouseDown: () => {
          console.log("on item click", item);
        },
        onDoubleClick: () =>
          item.itemProps.onDoubleClick && item.itemProps.onDoubleClick(),
      })}
    >
      {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

      <div
        style={{
          height: itemContext.dimensions.height,
          overflow: "hidden",
          paddingLeft: 3,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {itemContext.title}
      </div>

      {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
    </div>
  );
}
