/*
 * The majority of the code in this file is taken from here:
 * http://blog.tinisles.com/2011/10/google-authenticator-one-time-password-algorithm-in-javascript/
 */
import jsSHA from "jssha";

function dec2hex(s) {
  return (s < 15.5 ? "0" : "") + Math.round(s).toString(16);
}
function hex2dec(s) {
  return parseInt(s, 16);
}

function base32tohex(base32) {
  var base32chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ234567";
  var bits = "";
  var hex = "";

  for (var i = 0; i < base32.length; i++) {
    var val = base32chars.indexOf(base32.charAt(i).toUpperCase());
    bits += leftpad(val.toString(2), 5, "0");
  }

  for (i = i % 8; i > 0; i--) {
    bits += leftpad("0", 5, "0");
  }

  for (var i = 0; i + 4 <= bits.length; i += 4) {
    var chunk = bits.substr(i, 4);
    hex = hex + parseInt(chunk, 2).toString(16);
  }
  return hex;
}

function leftpad(str, len, pad) {
  if (len + 1 >= str.length) {
    str = Array(len + 1 - str.length).join(pad) + str;
  }
  return str;
}

export const getCurrentCode = (secret) => {
  var key = base32tohex(secret);
  var epoch = Math.round(new Date().getTime() / 1000.0);
  var time = leftpad(dec2hex(Math.floor(epoch / 30)), 16, "0");

  var shaObj = new jsSHA("SHA-1", "HEX");
  shaObj.setHMACKey(key, "HEX");
  shaObj.update(time);
  var hmac = shaObj.getHMAC("HEX");

  if (hmac == "KEY MUST BE IN BYTE INCREMENTS") {
    return "shit";
  } else {
    var offset = hex2dec(hmac.substring(hmac.length - 1));
  }

  var otp = (hex2dec(hmac.substr(offset * 2, 8)) & hex2dec("7fffffff")) + "";
  otp = otp.substr(otp.length - 6, 6);

  return otp;
};
