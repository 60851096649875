import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";
import { Navigate } from "react-big-calendar";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

class Toolbar extends React.Component {
  render() {
    let {
      localizer: { messages },
      label,
      date,
    } = this.props;

    return (
      <div className="rbc-toolbar">
        <span className="rbc-btn-group">
          <button
            type="button"
            onClick={this.navigate.bind(null, Navigate.TODAY)}
          >
            {messages.today}
          </button>
          <button
            type="button"
            onClick={this.navigate.bind(null, Navigate.PREVIOUS)}
          >
            {messages.previous}
          </button>
          <button
            type="button"
            onClick={this.navigate.bind(null, Navigate.NEXT)}
          >
            {messages.next}
          </button>
        </span>

        <span className="rbc-toolbar-label">
          <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/DD/yyyy"
              margin="normal"
              value={date}
              onChange={(x) => {
                this.navigateDate(x);
              }}
            />
          </MuiPickersUtilsProvider>
          <span style={{ display: "block" }}>{label}</span>
        </span>

        <span className="rbc-btn-group">{this.viewNamesGroup(messages)}</span>
      </div>
    );
  }

  navigateDate = (date) => {
    const parsed = Date.parse(date);
    if (
      parsed != NaN &&
      parsed > Date.parse(2000) &&
      parsed < Date.parse(6000)
    ) {
      this.props.onNavigate(null, date);
    }
  };

  navigate = (action) => {
    this.props.onNavigate(action);
  };

  view = (view) => {
    this.props.onView(view);
  };

  viewNamesGroup(messages) {
    let viewNames = this.props.views;
    const view = this.props.view;

    if (viewNames.length > 1) {
      return viewNames.map((name) => (
        <button
          type="button"
          key={name}
          className={clsx({ "rbc-active": view === name })}
          onClick={this.view.bind(null, name)}
        >
          {messages[name]}
        </button>
      ));
    }
  }
}

Toolbar.propTypes = {
  view: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.node.isRequired,
  localizer: PropTypes.object,
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  date: PropTypes.object,
};

export default Toolbar;
