import React from "react";
import Timeline from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import "./styles.css";
import moment from "moment";
import "moment/locale/lt";
import Modal from "@material-ui/core/Modal";
import EditEventModal from "./EditEventModal";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import BlockIcon from "@material-ui/icons/Block";
import { withStyles } from "@material-ui/core/styles";
import ItemRender from "./ItemRender";
import {
  DialogContent,
  CircularProgress,
  Backdrop,
  Tooltip,
} from "@material-ui/core";
import {
  visit_getAll,
  visit_DeleteVisit,
  template_GetTemplates,
  employee_GetEmployees,
} from "../../ApiClient";
import { ApprovalStatusLegend } from "./ApprovalStatus";

const styles = (theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    "& > *": {
      marginLeft: "15px",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
});

class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      items: [],
      employees: [],
      templates: [],

      isLoadingVisits: true,
      isLoadingTemplates: true,
      isLoadingEmployees: true,

      editingEvent: {},
    };
  }

  componentDidMount() {
    visit_getAll()
      .then((x) =>
        x.map((visit) => ({
          ...visit,
          itemProps: {
            onDoubleClick: () => this.onDoubleClick(visit.id),
          },
        }))
      )
      .then((x) => this.setState({ items: x, isLoadingVisits: false }));

    template_GetTemplates().then((x) =>
      this.setState({ templates: x, isLoadingTemplates: false })
    );

    employee_GetEmployees().then((x) => {
      const employees = x.map((employee) => ({
        id: employee.employeeId,
        title: employee.name,
      }));
      this.setState({
        employees,
        isLoadingEmployees: false,
      });
    });
  }

  isLoading = () =>
    this.state.isLoadingTemplates ||
    this.state.isLoadingVisits ||
    this.state.isLoadingEmployees;

  onDoubleClick = (eventId) => {
    const { items } = this.state;
    const eventIndex = items.findIndex((x) => x.id === eventId);
    if (eventIndex > -1) {
      this.setState({ editingEvent: items[eventIndex], modalOpen: true });
    }
  };

  onModalClose = () => this.setState({ modalOpen: false, editingEvent: {} });

  onAddEventClick = () => this.setState({ modalOpen: true });

  onAddBlockTimeClick = () =>
    this.setState({
      modalOpen: true,
      editingEvent: { metadata: { isBlock: true } },
    });

  onAddedEventCallback = (newEvent) => {
    let { items } = this.state;
    newEvent.itemProps = {
      onDoubleClick: () => this.onDoubleClick(newEvent.id),
    };

    const itemIndex = items.findIndex((x) => x.id === newEvent.id);
    if (itemIndex > -1) {
      items[itemIndex] = { ...items[itemIndex], ...newEvent };
    } else {
      items = items.concat([newEvent]);
    }

    this.setState({ items: [].concat(items) });
    this.onModalClose();
  };

  onEventDeleteCallback = () => {
    this.setState({ isLoading: true }, () =>
      visit_DeleteVisit(this.state.editingEvent.id).then(() => {
        const newItems = this.state.items.filter(
          (x) => x.id !== this.state.editingEvent.id
        );
        this.setState({ items: newItems, isLoading: false });
        this.onModalClose();
      })
    );
  };

  render() {
    const { modalOpen, items, employees } = this.state;
    const { classes } = this.props;

    return (
      <div>
        <tr>
          <td>
            <h1>Vizitų kalendorius</h1>
          </td>
          <td>
            <ApprovalStatusLegend />
          </td>
        </tr>

        {!this.isLoading() && (
          <Timeline
            groups={employees}
            items={items}
            defaultTimeStart={moment().add(-12, "hour")}
            defaultTimeEnd={moment().add(12, "hour")}
            itemHeightRatio={0.75}
            canMove={false}
            canResize={false}
            selected={[]}
            itemRenderer={ItemRender}
            stackItems
          />
        )}
        <Modal
          open={modalOpen}
          onClose={this.onModalClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <DialogContent>
            <EditEventModal
              onAddedEventCallback={this.onAddedEventCallback}
              onClose={this.onModalClose}
              employees={this.state.employees}
              templates={this.state.templates}
              eventData={this.state.editingEvent}
              onDelete={this.onEventDeleteCallback}
            />
          </DialogContent>
        </Modal>
        <div className={classes.fab}>
          <Tooltip title={"Blokuoti laiką"}>
            <Fab
              onClick={this.onAddBlockTimeClick}
              color="secondary"
              aria-label="block"
            >
              <BlockIcon />
            </Fab>
          </Tooltip>
          <Tooltip title={"Pridėti vizitą"}>
            <Fab
              onClick={this.onAddEventClick}
              color="primary"
              aria-label="add"
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        </div>
        <Backdrop className={classes.backdrop} open={this.isLoading()}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Calendar);
