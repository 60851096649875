import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  SideNav,
  Calendar,
  EmployeeList,
  TemplateList,
  Login,
  PrivateRoute,
  CalendarResourceGrid,
  EmployeeWorkHoursCalendar,
  MessagesIndex,
  MessagesHistory,
} from "./components";
import { authenticationService } from "./services";
import TwoFA from "./components/TwoFA/TwoFA";
import { ClientSearch } from "./components/ClientSearch";
import { ClientVisits } from "./components/ClientVisits";

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    width: "80%",
    padding: theme.spacing(3),
    height: "calc(100vh - 2 * 3rem)",
    ["@media (max-width: 960px)"]: { height: "auto" },
  },
});

class Router extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
    };
  }

  componentDidMount() {
    authenticationService.currentUser.subscribe((x) =>
      this.setState({ currentUser: x })
    );
  }

  render() {
    const { classes } = this.props;
    const { currentUser } = this.state;

    return (
      <BrowserRouter>
        <div className={classes.root}>
          {currentUser && <SideNav />}

          <main className={classes.content}>
            <Switch>
              <Route path="/login" component={Login} />
              <PrivateRoute exact path="/" component={CalendarResourceGrid} />
              <PrivateRoute exact path="/calendar-old" component={Calendar} />
              <PrivateRoute exact path="/employees" component={EmployeeList} />
              <PrivateRoute exact path="/templates" component={TemplateList} />
              <PrivateRoute exact path="/messages" component={MessagesIndex} />
              <PrivateRoute
                exact
                path="/employees/:employeeId"
                component={EmployeeWorkHoursCalendar}
              />
              <PrivateRoute
                exact
                path="/messages/:phoneNumber"
                component={MessagesHistory}
              />
              <PrivateRoute exact path="/client-search" component={ClientSearch} />
              <PrivateRoute exact path="/client-visits/:phoneNumber" component={ClientVisits} />
              <PrivateRoute exact path="/2fa-code" component={TwoFA} />
            </Switch>
          </main>
        </div>
      </BrowserRouter>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Router);
