import React from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";
import { message_GetRecieverPhoneNumbers } from "../../ApiClient";
import TextField from "@material-ui/core/TextField";
import {
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  Paper,
  TableHead,
  TableContainer,
  TableBody,
  Table,
  withStyles,
} from "@material-ui/core";

const styles = (theme) => ({
  table: {
    width: 500,
  },
  filterField: {
    paddingBottom: 16,
  },
});

class MessagesIndex extends React.Component {
  state = { phoneNumbers: [], isLoading: true, filter: "" };

  constructor(props) {
    super(props);

    message_GetRecieverPhoneNumbers().then((x) =>
      this.setState({ phoneNumbers: x, isLoading: false })
    );
  }

  onClickViewHistory = ({ reciever }) =>
    this.props.history.push(`/messages/${reciever}`);
  
  handleChange = (event) => {
    const target = event.target;
    const newState = { [target.name]: target.value };
    this.setState(newState);
  };

  render() {
    const { classes } = this.props;
    const { phoneNumbers, filter } = this.state;
    return (
      <div>
        <h1>Telefono numeriai</h1>

        <TextField
          className={classes.filterField}
          label={"Paieška"}
          name="filter"
          value={filter}
          onChange={this.handleChange}
        />

        <TableContainer className={classes.table} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "20%" }}>Veiksmai</TableCell>
                <TableCell>Numeris</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {phoneNumbers.length > 0 &&
                phoneNumbers.filter(x => x.reciever.includes(filter)).map((x) => (
                  <TableRow key={x.reciever}>
                    <TableCell>
                      <Tooltip title={"Peržiūrėti"}>
                        <IconButton onClick={() => this.onClickViewHistory(x)}>
                          <VisibilityIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{x.reciever}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(MessagesIndex);
