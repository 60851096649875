import React from "react";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";
import { message_GetMessagesIncludingNumber } from "../../ApiClient";
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  speechContainer: {
    width: "50%",
  },
  speechBubble: {
    position: "relative",
    borderRadius: ".4em",
    padding: "10px 10px",
    maxWidth: "60%",
    display: "table",
    marginTop: "20px",

    "&:after": {
      content: "",
      position: "absolute",
      top: "50%",
      width: 0,
      height: 0,
      border: "15px solid transparent",
      borderTop: 0,
      marginTop: "-7.5px",
    },

    "&-me": {
      background: "#424eff",
      marginLeft: "auto",
      textAlign: "right",

      "&:after": {
        right: 0,
        borderLeftColor: "#424eff",
        borderRight: 0,
        marginRight: "-15px",
      },
    },

    "&-them": {
      background: "#929292",
      marginRight: "auto",

      "&:after": {
        left: 0,
        borderRightColor: "#929292",
        borderLeft: 0,
        marginLeft: "-15px",
      },
    },
  },
});

class MessagesHistory extends React.Component {
  state = {
    messages: [],
    phoneNumber: "",
  };

  componentDidMount() {
    const { phoneNumber } = this.props.match.params;
    message_GetMessagesIncludingNumber(phoneNumber).then((messages) =>
      this.setState({ messages, phoneNumber })
    );
  }

  getMessageClass = ({ sender }, classes) => {
    const additionalClass =
      sender === this.state.phoneNumber
        ? classes.speechBubble + "-them"
        : classes.speechBubble + "-me";

    return `${classes.speechBubble} ${additionalClass}`;
  };

  render() {
    const { classes } = this.props;
    const { phoneNumber, messages } = this.state;

    return (
      <>
        <h1>Žinučiū istorija {phoneNumber}</h1>

        <div className={classes.speechContainer}>
          {messages.map((msg) => (
            <div className={this.getMessageClass(msg, classes)}>{msg.body}</div>
          ))}
        </div>
      </>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(MessagesHistory);
