import moment from "moment";
import { handleResponse, handleResponseWithoutAuth } from "./helpers";

const baseUrl = "/fizmed_api";

// ================ Visit ================ //
export const visit_getAll = () => {
  return fetch(`${baseUrl}/visit.php`, {
    method: "GET",
  })
    .then(handleResponse)
    .then((x) => x.map((visit) => createEventItem(visit)));
};

export const visit_GetByPhoneNumber = (phoneNumber) => {
  const number = phoneNumber.replace("+", "%2B");
  return fetch(`${baseUrl}/visit.php?phoneNumber=${number}`, {
    method: "GET",
  }).then(handleResponse);
};

export const visit_CreateVisit = (visit) => {
  return fetch(`${baseUrl}/visit.php`, {
    method: "POST",
    body: JSON.stringify(visit),
  })
    .then(handleResponse)
    .then((x) => createEventItem(x));
};

export const visit_UpdateVisit = (visit) => {
  return fetch(`${baseUrl}/visit.php`, {
    method: "PUT",
    body: JSON.stringify(visit),
  })
    .then(handleResponse)
    .then((x) => createEventItem(x));
};

export const visit_DeleteVisit = (visitId) => {
  return fetch(`${baseUrl}/visit.php?visitId=${visitId}`, {
    method: "DELETE",
  }).then(handleResponse);
};

// ================ Templates ==================== //
export const template_GetTemplates = () => {
  return fetch(`${baseUrl}/template.php`, {
    method: "GET",
  }).then(handleResponse);
};

export const template_CreateTemplate = (template) => {
  return fetch(`${baseUrl}/template.php`, {
    method: "POST",
    body: JSON.stringify(template),
  }).then(handleResponse);
};

export const template_UpdateTemplate = (template) => {
  return fetch(`${baseUrl}/template.php`, {
    method: "PUT",
    body: JSON.stringify(template),
  }).then(handleResponse);
};

export const template_DeleteTemplate = (templateId) => {
  return fetch(`${baseUrl}/template.php?templateId=${templateId}`, {
    method: "DELETE",
  }).then(handleResponse);
};

// ================ Employees ================ //
export const employee_GetEmployees = () => {
  return fetch(`${baseUrl}/employee.php`, {
    method: "GET",
  }).then(handleResponse);
};

export const employee_GetEmployee = (employeeId) => {
  return fetch(`${baseUrl}/employee.php?employeeId=${employeeId}`, {
    method: "GET",
  }).then(handleResponse);
};

export const employee_CreateEmployee = (employee) => {
  return fetch(`${baseUrl}/employee.php`, {
    method: "POST",
    body: JSON.stringify(employee),
  }).then(handleResponse);
};

export const employee_UpdateEmployee = (employee) => {
  return fetch(`${baseUrl}/employee.php`, {
    method: "PUT",
    body: JSON.stringify(employee),
  }).then(handleResponse);
};

export const employee_DeleteEmployee = (employeeId) => {
  return fetch(`${baseUrl}/employee.php?employeeId=${employeeId}`, {
    method: "DELETE",
  }).then(handleResponse);
};

// ================ Auth ================ //
export const auth_Login = (username, password) => {
  return fetch(`${baseUrl}/auth.php`, {
    method: "POST",
    body: JSON.stringify({ username, password }),
  }).then(handleResponseWithoutAuth);
};

export const auth_Logout = () => {
  return fetch(`${baseUrl}/auth.php`, {
    method: "DELETE",
  }).then(handleResponse);
};

// ================ Non-Working hours ================ //
export const nwh_GetAll = () => {
  return fetch(`${baseUrl}/nonWorkingHours.php`, {
    method: "GET",
  })
    .then(handleResponse)
    .then((x) => x.map((event) => createNwhItem(event)));
};

export const nwh_GetByEmployee = (employeeId) => {
  return fetch(`${baseUrl}/nonWorkingHours.php?employeeId=${employeeId}`, {
    method: "GET",
  })
    .then(handleResponse)
    .then((x) => x.map((event) => createNwhItem(event)));
};

export const nwh_Update = (event) => {
  return fetch(`${baseUrl}/nonWorkingHours.php`, {
    method: "PUT",
    body: JSON.stringify(event),
  })
    .then(handleResponse)
    .then(createNwhItem);
};

export const nwh_Create = (event) => {
  return fetch(`${baseUrl}/nonWorkingHours.php`, {
    method: "POST",
    body: JSON.stringify(event),
  })
    .then(handleResponse)
    .then(createNwhItem);
};

export const nwh_Delete = (id) => {
  return fetch(`${baseUrl}/nonWorkingHours.php?id=${id}`, {
    method: "DELETE",
  }).then(handleResponse);
};

// ================ Phone numbers ================ //
export const phoneNumbers_GetAll = () => {
  return fetch(`${baseUrl}/phoneNumbers.php`, {
    method: "GET",
  })
    .then(handleResponse)
    .then((phoneNumbers) =>
      phoneNumbers.map((x) => x.phoneNumber).filter((x) => x && x !== "")
    );
};

export const phoneNumbers_GetWithComments = () => {
  return fetch(`${baseUrl}/phoneNumbers.php?comments=true`, {
    method: "GET"
  })
    .then(handleResponse)
    .then((phoneNumbers) =>
      phoneNumbers.map((x) => ({
        phoneNumber: x.phoneNumber,
        comment: x.comment
      })).filter((x) => x && x.phoneNumber !== "" && x.phoneNumber !== "+370")
    );
}

// ================ Approve Visit ================ //
export const approveVisit_Approve = (visitId) => {
  return fetch(`${baseUrl}/approveVisit.php`, {
    method: "POST",
    body: JSON.stringify({ visitId }),
  })
    .then(handleResponse)
    .then((x) => createEventItem(x));
};

// ================ Remind Visit ================ //
export const remindVisit_Remind = (visitId) => {
  return fetch(`${baseUrl}/remindVisit.php`, {
    method: "POST",
    body: JSON.stringify({ visitId }),
  })
    .then(handleResponse)
    .then((x) => createEventItem(x));
};

// ================ Message ================ //
export const message_GetRecieverPhoneNumbers = () => {
  return fetch(`${baseUrl}/message.php`, {
    method: "GET",
  }).then(handleResponse);
};

export const message_GetMessagesIncludingNumber = (phoneNumber) => {
  const number = phoneNumber.replace("+", "%2B");
  return fetch(`${baseUrl}/message.php?phoneNumber=${number}`, {
    method: "GET",
  }).then(handleResponse);
};

// === Mappers === //
const createEventItem = (visit) => ({
  id: visit.visitId,
  group: visit.employeeId,
  title: visit.comment,
  start_time: moment.utc(visit.visitDate),
  end_time: moment.utc(visit.endDate),
  metadata: {
    notes: visit.notes,
    phoneNumber: visit.phoneNumber,
    templateId: visit.templateId,
    reminderId: visit.reminderId,
    smsAprovalStatus: visit.smsAprovalStatus,
    isBlock: convertIsBlock(visit.isBlock),
  },
});

const createNwhItem = (event) => ({
  id: event.id,
  title: event.title,
  employeeId: event.employeeId,
  start: moment.utc(event.start),
  end: moment.utc(event.end),
});

const convertIsBlock = (value) =>
  typeof value === "boolean" ? value : !!parseInt(value);
