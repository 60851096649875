import React from "react";

export const colors = {
  red: {
    primary: "#f55145",
    secondary: "#FAE3E3",
  },
  blue: {
    primary: "#1e90ff",
    secondary: "#D1E8FF",
  },
  yellow: {
    primary: "#ff9e0f",
    secondary: "#FDF1BA",
  },
  green: {
    primary: "#55b559",
    secondary: "#76CF79",
  },
  grey: {
    primary: "#777777",
    secondary: "#777777",
  },
  violet: {
    primary: "#5f37ae",
    secondary: "#693dc2",
  },
};

const approvalStatusEnum = {
  NotSent: 0,
  NotAnswered: 1,
  Approved: 2,
  Declined: 3,
  Unknown: 4,
};

const legend = [
  { title: "Priminimas išsiųstas", color: colors.yellow },
  { title: "Vizitas patvirtintas", color: colors.green },
  { title: "Vizitas atšauktas", color: colors.red },
  { title: "Žinutė atsakyta", color: colors.violet },
];

export const getColorByStatus = (approvalStatus, reminderId) => {
  switch (parseInt(approvalStatus)) {
    case approvalStatusEnum.Approved:
      return colors.green;
    case approvalStatusEnum.Declined:
      return colors.red;
    case approvalStatusEnum.Unknown:
      return colors.violet;
    default:
      if (!reminderId || reminderId === 0 || reminderId === "0")
        return colors.grey;
      return colors.yellow;
  }
};

export const getStatusLabelByColor = ({ primary, secondary }) => {
  const legendByColor = legend.find(x => x.color.primary == primary && x.color.secondary == secondary);
  return legendByColor ? legendByColor.title : "Nežinomas";
};

export const ApprovalStatusLegend = () => (
  <div>
    {legend.map((x) => (
      <div className={"legend-item"}>
        <span
          class="circle"
          style={{ backgroundColor: x.color.primary }}
        ></span>
        <span>{x.title}</span>
      </div>
    ))}
  </div>
);
