import React from "react";
import Router from "./Router";
import { createStore, combineReducers } from "redux";
import { Provider } from "react-redux";
import { SnackBarReducer, SnackBarContainer } from "./components";
import "./App.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

const store = createStore(
  combineReducers({
    snackBar: SnackBarReducer,
  })
);

function App() {
  return (
    <Provider store={store}>
      <Router />
      <SnackBarContainer />
    </Provider>
  );
}

export default App;
