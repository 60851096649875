import moment from "moment";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MomentUtils from "@date-io/moment";
import Select from "@material-ui/core/Select";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import { nwh_Update, nwh_Create } from "../../ApiClient";

const styles = (theme) => ({
  container: {
    minWidth: "500px",
  },
  textField: {
    width: "100%",
    paddingBottom: "1.25rem",
    marginTop: "0",
  },
  selectField: {
    width: "100%",
  },
  saveButton: {
    margin: "10px",
  },
});

const strings = {
  title: "Ne darbo valandos",
  buttons: {
    save: "Išsaugoti",
    cancel: "Atšaukti",
    delete: "Naikinti",
  },
  inputs: {
    title: "Pavadinimas",

    dateStart: "Pradžios Data",
    timeStart: "Pradžios Laikas",

    dateEnd: "Pabaigos Data",
    timeEnd: "Pabaigos Laikas",
  },
};

class BlockTimeModal extends React.Component {
  constructor(props) {
    super(props);

    const { eventData } = props;

    const dateStart = eventData?.start || moment();
    const dateEnd = eventData?.end || moment();

    const stateData = {
      title: "",

      dateEnd: dateEnd,
      timeEnd: dateEnd.format("HH:mm"),

      dateStart: dateStart,
      timeStart: dateStart.format("HH:mm"),
    };

    if (props.eventData && props.eventData.id) {
      const { eventData } = this.props;
      stateData.title = eventData.title;

      stateData.dateEnd = eventData.end.local();
      stateData.timeEnd = eventData.end.format("HH:mm");

      stateData.dateStart = eventData.start.local();
      stateData.timeStart = eventData.start.format("HH:mm");
    }

    this.state = {
      ...stateData,
    };
  }

  handleChange = (event) => {
    const target = event.target;
    const newState = { [target.name]: target.value };

    if (target.name === "timeStart") {
      const timeStr = target.value.split(":");
      let hourInt = parseInt(timeStr[0]);
      if (!Number.isNaN(hourInt)) {
        hourInt++;
        let hourStr = hourInt > 9 ? hourInt : `0${hourInt}`;
        if (parseInt(hourStr) >= 24) hourStr = timeStr[0];
        newState["timeEnd"] = `${hourStr}:${timeStr[1]}`;
      }
    }

    this.setState(newState);
  };

  handleStartDateChange = (date) => {
    this.setState({ dateStart: date, dateEnd: date });
  };

  handleEndDateChange = (date) => {
    this.setState({ dateEnd: date });
  };

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  onClose = () => this.props.onClose();

  onSave = () => {
    const { title, dateStart, timeStart, dateEnd, timeEnd } = this.state;
    const { employeeId } = this.props;
    const momentTimeStart = moment(timeStart, "HH:mm");
    const momentTimeEnd = moment(timeEnd, "HH:mm");

    dateStart.set({
      hour: momentTimeStart.get("hour"),
      minute: momentTimeStart.get("minute"),
    });

    dateEnd.set({
      hour: momentTimeEnd.get("hour"),
      minute: momentTimeEnd.get("minute"),
    });

    const newItemDto = {
      title: title,
      employeeId: employeeId,
      start: dateStart,
      end: dateEnd,
    };

    const { eventData } = this.props;
    if (eventData && eventData.id) {
      newItemDto.id = eventData.id;
      nwh_Update(newItemDto).then((newEvent) => {
        this.props.onAddedEventCallback(newEvent);
      });
    } else {
      nwh_Create(newItemDto).then((newEvent) => {
        this.props.onAddedEventCallback(newEvent);
      });
    }
  };

  showDeleteButton = () => this.props.eventData && this.props.eventData.id;

  render() {
    const { classes, onDelete } = this.props;

    return (
      <div className={classes.container}>
        <h2>{strings.title}</h2>

        <TextField
          className={classes.textField}
          required
          id="textfield-title"
          label={strings.inputs.title}
          name="title"
          value={this.state.title}
          onChange={this.handleChange}
        />

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
              <KeyboardDatePicker
                className={classes.textField}
                required
                disableToolbar
                variant="inline"
                format="MM/DD/yyyy"
                margin="normal"
                id="textfield-date-start"
                label={strings.inputs.dateStart}
                value={this.state.dateStart}
                onChange={this.handleStartDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <TextField
              className={classes.textField}
              required
              id="time-start"
              name="timeStart"
              label={strings.inputs.timeStart}
              type="time"
              defaultValue="00:00"
              value={this.state.timeStart}
              onChange={this.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 30 * 60, // 30 min
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
              <KeyboardDatePicker
                className={classes.textField}
                required
                disableToolbar
                variant="inline"
                format="MM/DD/yyyy"
                margin="normal"
                id="textfield-date-end"
                label={strings.inputs.dateEnd}
                value={this.state.dateEnd}
                onChange={this.handleEndDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <TextField
              className={classes.textField}
              required
              id="time-end"
              name="timeEnd"
              label={strings.inputs.timeEnd}
              type="time"
              defaultValue="00:00"
              value={this.state.timeEnd}
              onChange={this.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 30 * 60, // 30 min
              }}
            />
          </Grid>
        </Grid>

        <br />

        <Button
          className={classes.saveButton}
          startIcon={<SaveIcon />}
          variant="contained"
          color="primary"
          onClick={this.onSave}
        >
          {strings.buttons.save}
        </Button>

        <Button
          className={classes.saveButton}
          startIcon={<ClearIcon />}
          variant="contained"
          color="secondary"
          onClick={this.onClose}
        >
          {strings.buttons.cancel}
        </Button>

        {this.showDeleteButton() && (
          <Button
            className={classes.saveButton}
            startIcon={<DeleteIcon />}
            variant="contained"
            color="secondary"
            onClick={onDelete}
          >
            {strings.buttons.delete}
          </Button>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(BlockTimeModal);
