import React from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { withRouter } from "react-router";
import compose from "recompose/compose";
import { calendarDefaults } from "../Common";
import {
  DialogContent,
  Dialog,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import BlockTimeModal from "./BlockTimeModal";
import {
  nwh_GetByEmployee,
  nwh_Delete,
  employee_GetEmployee,
} from "../../ApiClient";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  calendar: {
    height: "95%",
  },
});

class EmployeeWorkHoursCalendar extends React.Component {
  state = {
    employeeId: 0,
    modalOpen: false,
    editingEvent: {},
    events: [],
    employeeData: {},
  };

  componentDidMount() {
    const { employeeId } = this.props.match.params;
    if (employeeId > 0) {
      nwh_GetByEmployee(employeeId).then((events) => {
        this.setState({ employeeId, events });
      });

      employee_GetEmployee(employeeId).then((employeeData) => {
        this.setState({ employeeData });
      });
    }
  }

  onSelectSlot = ({ start, end }) =>
    this.setState({
      modalOpen: true,
      editingEvent: {
        end: moment(end),
        start: moment(start),
      },
    });

  onModalClose = () => this.setState({ modalOpen: false, editingEvent: {} });

  onSelectEvent = (event) =>
    this.setState({ editingEvent: event, modalOpen: true });

  onAddedEventCallback = (newEvent) => {
    let { events } = this.state;

    const itemIndex = events.findIndex((x) => x.id === newEvent.id);
    if (itemIndex > -1) {
      events[itemIndex] = { ...events[itemIndex], ...newEvent };
    } else {
      events = events.concat([newEvent]);
    }

    this.setState({ events: [].concat(events) });
    this.onModalClose();
  };

  onEventDeleteCallback = () => {
    this.setState({ isLoading: true }, () =>
      nwh_Delete(this.state.editingEvent.id).then(() => {
        const newItems = this.state.events.filter(
          (x) => x.id !== this.state.editingEvent.id
        );
        this.setState({ events: newItems, isLoading: false });
        this.onModalClose();
      })
    );
  };

  render() {
    const { classes } = this.props;
    const {
      modalOpen,
      editingEvent,
      events,
      employeeId,
      employeeData,
    } = this.state;
    const isMobile = window.matchMedia("(max-width: 960px)").matches;

    return (
      <>
        <h1>
          Ne darbo laiko kalendorius{" "}
          {employeeData?.name && <>[{employeeData.name}]</>}
        </h1>

        <Calendar
          selectable
          events={events}
          defaultView={Views.WEEK}
          className={classes.calendar}
          views={["week"]}
          defaultDate={new Date(2018, 0, 1)}
          onSelectSlot={this.onSelectSlot}
          onSelectEvent={this.onSelectEvent}
          startAccessor={({ start }) => new Date(start)}
          endAccessor={({ end }) => new Date(end)}
          components={{
            toolbar: () => <></>,
          }}
          {...calendarDefaults}
        />
        <Dialog
          fullScreen={isMobile}
          open={modalOpen}
          onClose={this.onModalClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <BlockTimeModal
              onAddedEventCallback={this.onAddedEventCallback}
              onClose={this.onModalClose}
              eventData={editingEvent}
              onDelete={this.onEventDeleteCallback}
              employeeId={employeeId}
            />
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(EmployeeWorkHoursCalendar);
