import React from "react";
import {
  TableContainer,
  Paper,
  Tab,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import TableDataRow from "./TableDataRow";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";

const styles = (theme) => ({
  table: {
    minWidth: 650,
  },
  button: {
    margin: "10px",
  },
  progress: {
    padding: "20px",
    margin: "auto",
    display: "flex",
  },
});

class TableComponent extends React.Component {
  state = {
    newData: [],
    newCounter: -1,
    isEdit: false,
  };

  onAddRow = () => {
    const { newData, newCounter } = this.state;
    newData.push({ new: newCounter });
    this.setState({ newData, newCounter: newCounter - 1, isEdit: true });
  };

  onCancelAdd = (newId) => {
    const newData = this.state.newData.filter((x) => x.new !== newId);
    this.setState({ newData, isEdit: false });
  };

  onSave = (data) => {
    const promise = this.props.onSave(data);
    if (promise && promise.then) {
      return promise.then((updatedData) => {
        const newData = this.state.newData.filter((x) => x.new !== data.new);
        this.setState({ newData, isEdit: false });
        console.log(updatedData);
        return updatedData;
      });
    }
  };

  render() {
    const {
      classes,
      columns,
      className,
      onDelete,
      data,
      isLoading,
      extraActions,
    } = this.props;
    const {
      isEdit,
      newData,
    } = this.state;

    return (
      <TableContainer className={className} component={Paper}>
        <Table
          className={classes.table}
          size="small"
          style={{ tableLayout: "auto" }}
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "20%" }}>Veiksmai</TableCell>
              {columns.map((col, index) => (
                <TableCell key={index}>{col.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(isLoading && (
              <TableRow>
                <TableCell colSpan={columns.length + 1}>
                  <CircularProgress size={40} className={classes.progress} />
                </TableCell>
              </TableRow>
            )) ||
              data
                .concat(newData)
                .map((row, index) => (
                  <TableDataRow
                    key={index}
                    columns={columns}
                    data={row}
                    onSave={this.onSave}
                    onDelete={onDelete}
                    onCancelAdd={this.onCancelAdd}
                    extraActions={extraActions}
                  />
                ))}
            <TableRow>
              <Button
                variant="contained"
                color="primary"
                disabled={isLoading || isEdit}
                startIcon={<PlaylistAddIcon />}
                className={classes.button}
                onClick={this.onAddRow}
              >
                Pridėti
              </Button>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }))(TableComponent);
