import React from "react";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { authenticationService } from "../../services";
import { withRouter } from "react-router";
import { popupOpen, severity } from "../SnackBar";
import { connect } from "react-redux";

const styles = () => ({
  paper: {
    padding: "10px",
    display: "grid",
    margin: "auto",
    width: "300px",
    "& > *": {
      marginBottom: "15px",
    },
  },
});

class Login extends React.Component {
  constructor(props) {
    super(props);

    // redirect to home if already logged in
    if (authenticationService.currentUserValue?.loggedIn) {
      this.props.history.push("/");
    }

    this.state = {
      nickname: "",
      password: "",
    };
  }

  onSubmit = () => {
    authenticationService.login(this.state.nickname, this.state.password).then(
      (user) => {
        const { from } = this.props.location.state || {
          from: { pathname: "/" },
        };
        this.props.history.push(from);
      },
      (error) => {
        this.props.dispatch(popupOpen(error, severity.error));
      }
    );
  };

  handleChange = (event) => {
    const target = event.target;

    this.setState({ [target.name]: target.value });
  };

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.paper} variant="outlined" square>
        <TextField
          id="nickname-input"
          label="Naudotojo vardas"
          autoComplete="current-nickname"
          name="nickname"
          value={this.state.nickname}
          onChange={this.handleChange}
        />

        <TextField
          id="password-input"
          label="Slaptažodis"
          type="password"
          autoComplete="current-password"
          name="password"
          value={this.state.password}
          onChange={this.handleChange}
        />

        <Button
          startIcon={<LockIcon />}
          variant="contained"
          color="primary"
          onClick={this.onSubmit}
        >
          Prisijųngti
        </Button>
      </Paper>
    );
  }
}

export default compose(connect(), withRouter, withStyles(styles))(Login);
