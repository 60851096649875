import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import { popupClose } from "./actions";
import { connect } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function SnackBarContainer() {
  const dispatch = useDispatch();

  const { text, severity, open } = useSelector((state) => state.snackBar);

  function handleClose() {
    dispatch(popupClose());
  }

  return (
    <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity}>
        {text}
      </Alert>
    </Snackbar>
  );
}

export default connect()(SnackBarContainer);
