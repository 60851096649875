import * as Actions from "./actions";

const initialState = {
  open: false,
  text: "I love snacks",
  severity: "info",
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case Actions.POPUP_OPEN:
      return {
        ...state,
        open: true,
        text: action.text,
        severity: action.severity,
      };
    case Actions.POPUP_CLOSE:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
}
