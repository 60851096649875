import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  TextField,
} from "@material-ui/core";
import { ConfirmDialog } from ".";
import { deepEqual } from "../../helpers";

class TableDataRow extends React.Component {
  state = {
    isEdit: false,
    data: this.props.data,
    confirmOpen: false,
    isLoading: false,
  };

  componentDidMount() {
    if (this.state.data.new) {
      this.setState({ isEdit: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (!deepEqual(prevProps.data, this.props.data)) {
      this.setState({ data: this.props.data });
    }
  }

  onEdit = () => {
    this.setState({ isEdit: true, oldData: { ...this.state.data } });
  };

  onCancelEdit = () => {
    if (this.state.data?.new) {
      this.props.onCancelAdd(this.state.data.new);
    } else {
      this.setState({ isEdit: false, data: { ...this.state.oldData } });
    }
  };

  onSave = () => {
    const data = this.state.data;
    data.new = false;
    const promise = this.props.onSave(data);

    if (promise) {
      this.setState({ isLoading: true });
      promise.then((updatedData) =>
        this.setState({ isLoading: false, isEdit: false, data: updatedData })
      );
    } else {
      this.setState({ isEdit: false, data: data });
    }
  };

  onConfirmDelete = () => {
    this.props.onDelete(this.state.data);
    this.setState({ confirmOpen: false });
  };

  handleChange = (event) => {
    const target = event.target;

    const newData = this.state.data;
    newData[target.name] = target.value;

    this.setState({ data: newData });
  };

  actions = [
    {
      name: "Redaguoti",
      icon: <EditIcon fontSize="small" />,
      onClick: this.onEdit,
    },
    {
      name: "Panaikinti",
      icon: <DeleteIcon fontSize="small" />,
      onClick: () => this.setConfirmOpen(true),
    },
  ];

  actionsEditing = [
    {
      name: "Išsaugoti",
      icon: <SaveIcon fontSize="small" />,
      onClick: this.onSave,
    },
    {
      name: "Atšaukti",
      icon: <CancelIcon fontSize="small" />,
      onClick: this.onCancelEdit,
    },
  ];

  getActions = () =>
    this.state.isEdit
      ? this.actionsEditing
      : this.actions.concat(this.props.extraActions || []);
  setConfirmOpen = (value) => this.setState({ confirmOpen: value });

  renderCell = ({ name, multiline, type }) => {
    const value = this.state.data ? this.state.data[name] : "";

    return (
      <TableCell>
        {this.state.isEdit ? (
          <TextField
            id={`textfield-${name}`}
            name={name}
            value={value}
            onChange={this.handleChange}
            multiline={multiline}
            rowsMax={4}
            disabled={this.state.isLoading}
            type={type || "text"}
          />
        ) : (
          <>{type === "password" ? "***" : value || ""}</>
        )}
      </TableCell>
    );
  };

  render() {
    const { columns, index, data } = this.props;

    return (
      <TableRow key={index}>
        <TableCell>
          {this.getActions().map((action, actionIndex) => (
            <Tooltip key={actionIndex} title={action.name}>
              <IconButton
                disabled={this.state.isLoading}
                onClick={() => action.onClick(data)}
              >
                {action.icon}
              </IconButton>
            </Tooltip>
          ))}
        </TableCell>
        {columns.map((col, colIndex) => (
          <this.renderCell
            key={colIndex}
            name={col.key}
            type={col.type}
            multiline={col.multiline}
          />
        ))}
        <ConfirmDialog
          title="Ištrinti"
          open={this.state.confirmOpen}
          setOpen={this.setConfirmOpen}
          onConfirm={this.onConfirmDelete}
        >
          Ar tikrai norite ištrinti šį įrašą?
        </ConfirmDialog>
      </TableRow>
    );
  }
}

export default TableDataRow;
