import React from "react";
import { Table } from "..";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import {
  template_GetTemplates,
  template_CreateTemplate,
  template_UpdateTemplate,
  template_DeleteTemplate,
} from "../../ApiClient";

const styles = (theme) => ({
  table: {
    width: 750,
  },
});

class TemplateList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      templates: [],
      isLoading: true,
    };

    template_GetTemplates().then((x) =>
      this.setState({ templates: x, isLoading: false })
    );
  }

  columns = [
    { title: "Pavadinimas", key: "title" },
    { title: "Turinys", key: "body", multiline: true },
  ];

  onSave = (template) => {
    if (template.templateId && template.templateId !== 0) {
      return template_UpdateTemplate(template).then((updatedTemplate) => {
        const { templates } = this.state;
        const updatedIndex = templates.findIndex(
          (x) => x.templateId == updatedTemplate.templateId
        );
        if (updatedIndex < 0 || updatedTemplate.templateId < 1) {
          // TODO: Handle error
          return;
        }
        templates[updatedIndex] = updatedTemplate;
        this.setState({ templates });
        return updatedTemplate;
      });
    } else {
      return template_CreateTemplate(template).then((newTemplate) => {
        const { templates } = this.state;
        templates.push(newTemplate);
        this.setState({ templates });
        return newTemplate;
      });
    }
  };

  onDelete = (template) =>
    template_DeleteTemplate(template.templateId).then(() => {
      const newTemplates = this.state.templates.filter(
        (x) => x.templateId != template.templateId
      );

      this.setState({ templates: newTemplates });
    });

  render() {
    const { classes } = this.props;
    return (
      <div>
        <h1>Šablonai</h1>
        <Table
          className={classes.table}
          columns={this.columns}
          data={this.state.templates}
          onSave={this.onSave}
          onDelete={this.onDelete}
          isLoading={this.state.isLoading}
        />
      </div>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }))(TemplateList);
